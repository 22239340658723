import { buildContentfulImageURL } from '@utils/image';

export const optimizeImageResolution = (images = []) => {
  const srcSet = {};
  images
    .sort((img, nextImg) => img.width - nextImg.width)
    .forEach((img, i) => {
      srcSet[`${i + 1}x`] = img.path;
    });
  return srcSet;
};

export const optimizeImageResolutionSrcSet = (srcSetObj) => {
  let srcSet = '';
  const srcSetKeys = Object.keys(srcSetObj);
  srcSetKeys.forEach((resolution, i) => {
    const path = srcSetObj[resolution];
    srcSet += `${path} ${resolution}${i !== srcSetKeys.length - 1 ? ', ' : ''}`;
  });
  return srcSet;
};

export const optimizeImageSet = (resizeImages) => {
  const srcSetObj = optimizeImageResolution(resizeImages.images);
  const srcSet = optimizeImageResolutionSrcSet(srcSetObj);
  const lowResImg = srcSetObj['1x'];
  return [
    {
      src: srcSet,
    },
    {
      src: lowResImg,
    },
  ];
};

export const heroImageSet = (image, mobileImage) => {
  return [
    {
      src: `${buildContentfulImageURL(mobileImage || image, {
        width: 576,
        height: 745,
        fit: 'fill',
        format: 'webp',
      })} 1x, ${buildContentfulImageURL(mobileImage || image, {
        width: 960,
        height: 1242,
        fit: 'fill',
        format: 'webp',
      })} 2x`,
      media: '(max-width: 576px)',
      type: 'image/webp',
    },
    {
      src: `${buildContentfulImageURL(mobileImage || image, {
        width: 576,
        height: 745,
        fit: 'fill',
        format: 'jpg',
      })} 1x, ${buildContentfulImageURL(mobileImage || image, {
        width: 960,
        height: 1242,
        fit: 'fill',
        format: 'jpg',
      })} 2x`,
      media: '(max-width: 576px)',
      type: 'image/jpeg',
    },
    {
      src: `${buildContentfulImageURL(image, {
        width: 768,
        height: 788,
        format: 'webp',
      })} 1x, ${buildContentfulImageURL(image, {
        width: 1536,
        height: 1576,
        format: 'webp',
      })} 2x`,
      media: '(max-width: 768px)',
      type: 'image/webp',
    },
    {
      src: `${buildContentfulImageURL(image, {
        width: 768,
        height: 788,
        format: 'jpg',
      })} 1x, ${buildContentfulImageURL(image, {
        width: 1536,
        height: 1576,
        format: 'jpg',
      })} 2x`,
      media: '(max-width: 768px)',
      type: 'image/jpeg',
    },
    {
      src: `${buildContentfulImageURL(image, {
        width: 1024,
        format: 'webp',
      })} 1x, ${buildContentfulImageURL(image, {
        width: 2048,
        format: 'webp',
      })} 2x`,
      media: '(max-width: 1024px)',
      type: 'image/webp',
    },
    {
      src: `${buildContentfulImageURL(image, {
        width: 1024,
        format: 'jpg',
      })} 1x, ${buildContentfulImageURL(image, {
        width: 2048,
        format: 'jpg',
      })} 2x`,
      media: '(max-width: 1024px)',
      type: 'image/jpeg',
    },
    {
      src: `${buildContentfulImageURL(image, {
        width: 1440,
        format: 'webp',
      })} 1x, ${buildContentfulImageURL(image, {
        width: 2880,
        format: 'webp',
      })} 2x`,
      media: '(max-width: 1440px)',
      type: 'image/webp',
    },
    {
      src: `${buildContentfulImageURL(image, {
        width: 1440,
        format: 'jpg',
      })} 1x, ${buildContentfulImageURL(image, {
        width: 2880,
        format: 'jpg',
      })} 2x`,
      media: '(max-width: 1440px)',
      type: 'image/jpeg',
    },
    {
      src: buildContentfulImageURL(image, { width: 2560, format: 'webp' }),
      type: 'image/webp',
    },
    {
      src: buildContentfulImageURL(image, { width: 2560, format: 'jpg' }),
      type: 'image/jpeg',
    },
  ];
};

export const curatedImageSet = (image) => {
  return [
    {
      src: `${buildContentfulImageURL(image, {
        width: 576,
        height: 576,
        fit: 'fill',
        focus: 'top',
        format: 'webp',
      })} 1x, ${buildContentfulImageURL(image, {
        width: 960,
        height: 960,
        fit: 'fill',
        focus: 'top',
        format: 'webp',
      })} 2x`,
      media: '(max-width: 576px)',
      type: 'image/webp',
    },
    {
      src: `${buildContentfulImageURL(image, {
        width: 576,
        height: 576,
        fit: 'fill',
        focus: 'top',
        format: 'jpg',
      })} 1x, ${buildContentfulImageURL(image, {
        width: 960,
        height: 960,
        fit: 'fill',
        focus: 'top',
        format: 'jpg',
      })} 2x`,
      media: '(max-width: 576px)',
      type: 'image/jpeg',
    },
    {
      src: `${buildContentfulImageURL(image, {
        width: 700,
        height: 700,
        fit: 'fill',
        focus: 'top',
        format: 'webp',
      })} 1x, ${buildContentfulImageURL(image, {
        width: 1400,
        height: 1400,
        fit: 'fill',
        focus: 'top',
        format: 'webp',
      })} 2x`,
      type: 'image/webp',
    },
    {
      src: `${buildContentfulImageURL(image, {
        width: 700,
        height: 700,
        fit: 'fill',
        focus: 'top',
        format: 'jpg',
      })} 1x, ${buildContentfulImageURL(image, {
        width: 1400,
        height: 1400,
        fit: 'fill',
        focus: 'top',
        format: 'jpg',
      })} 2x`,
      type: 'image/jpeg',
    },
    {
      src: buildContentfulImageURL(image, {
        width: 700,
        height: 700,
        fit: 'fill',
        focus: 'top',
        format: 'webp',
      }),
      type: 'image/webp',
    },
    {
      src: buildContentfulImageURL(image, {
        width: 700,
        height: 700,
        fit: 'fill',
        focus: 'top',
        format: 'jpg',
      }),
      type: 'image/jpeg',
    },
  ];
};
